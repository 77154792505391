import T1 from "../../../images/About/Nishara.png";
import T2 from "../../../images/About/Jayani.png";
import T3 from "../../../images/About/Pasindu.png";
import T4 from "../../../images/About/Thakshala.png";

const teamdata = [
  {
    id: 1,
    img: T1,
    member: "Nishara Jayasinghe",
    position: "General Manager (Surveyor)",
  },
  {
    id: 2,
    img: T2,
    member: "Jayani Nadunika",
    position: "Sales & Support (Surveyor)",
  },
  {
    id: 3,
    img: T3,
    member: "Pasindu Hettiarachchi",
    position: "Design Engineer (Eng.)",
  },
  {
    id: 4,
    img: T4,
    member: "Thakshala Witharanage",
    position: "Sales Coordinator",
  },
];

export default teamdata;
